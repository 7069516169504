<template>
  <div>
    <ul>
      <li><router-link :to="{ name: 'StaffDashboard' }" @click="hideMenu">ダッシュボード</router-link></li>
      <li><router-link :to="{ name: 'EvalLog' }" @click="hideMenu">定期評価</router-link></li>
      <li><router-link :to="{ name: 'BonusLog' }" @click="hideMenu">賞与評価</router-link></li>
      <li><router-link :to="{ name: 'SelfEvalLog' }" @click="hideMenu">自己評価</router-link></li>
    </ul>
  </div>
</template>

<script>
export default {
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {
    hideMenu () {
      this.$store.commit('showMenu', false)
    }
  }
}
</script>
