import { createStore } from 'vuex'
import Auth from './auth.js'
import Admin from './admin.js'
import Initial from './initial.js'
import Defs from './defs.js'
import Eval from './eval.js'
import Modal from './modal.js'
import SelfExam from './selfexam.js'
import Client from './client.js'
import Owner from './owner.js'
import Manager from './manager.js'
import Staff from './staff.js'
import Jirei from './jirei.js'

export default createStore({
  state: {
    processing: false,
    updated: false,
    mode: '',
    adminMode: false,
    title: localStorage.getItem('title'),
    titleAdd: '',
    company: localStorage.getItem('company'),
    name: localStorage.getItem('name'),
    showMenu: false,
    showError: false,
    errorMsg: '',
    currentStaffId: '',
    currentTemplateName: '',
    askeval: []
  },
  getters: {
    processing: (state) => state.processing,
    updated: (state) => state.updated,
    mode: (state) => state.mode,
    adminMode: (state) => state.adminMode,
    title: (state) => state.title,
    titleAdd: (state) => state.titleAdd,
    company: (state) => state.company,
    name: (state) => state.name,
    showMenu: (state) => state.showMenu,
    showError: (state) => state.showError,
    errorMsg: (state) => state.errorMsg,
    currentStaffId: (state) => state.currentStaffId,
    currentTemplateName: (state) => state.currentTemplateName,
    askeval: (state) => state.askeval
  },
  mutations: {
    processing (state, processing) {
      state.processing = processing
    },
    updated (state, updated) {
      state.updated = updated
      if (updated) {
        const interval = setInterval(() => {
          state.updated = false
          clearInterval(interval)
        }, 4000)
      }
    },
    mode (state, mode) {
      state.mode = mode
    },
    adminMode (state, adminMode) {
      state.adminMode = adminMode
    },
    title (state, title) {
      state.title = title
      localStorage.setItem('title', title)
    },
    titleAdd (state, titleAdd) {
      state.titleAdd = titleAdd
    },
    company (state, company) {
      state.company = company
      if (company) {
        localStorage.setItem('company', company)
      } else {
        localStorage.removeItem('company')
      }
    },
    name (state, name) {
      state.name = name
      if (name) {
        localStorage.setItem('name', name)
      } else {
        localStorage.removeItem('name')
      }
    },
    showMenu (state, showMenu) {
      state.showMenu = showMenu
    },
    showError (state, showError) {
      state.showError = showError
    },
    errorMsg (state, errorMsg) {
      state.errorMsg = errorMsg
    },
    currentStaffId (state, currentStaffId) {
      state.currentStaffId = currentStaffId
    },
    currentTemplateName (state, currentTemplateName) {
      state.currentTemplateName = currentTemplateName
    },
    askeval (state, askeval) {
      state.askeval = askeval
    }
  },
  actions: {
    ShowError ({ commit }, msg) {
      commit('showError', true)
      commit('errorMsg', msg)
    }
  },
  modules: {
    Auth,
    Admin,
    Initial,
    Defs,
    Eval,
    Modal,
    SelfExam,
    Client,
    Owner,
    Manager,
    Staff,
    Jirei
  }
})
