<template>
  <div>
    <ul>
      <li><router-link :to="{ name: 'Client', params: { dom: domain } }">ダッシュボード</router-link></li>
      <li><router-link :to="{ name: 'StaffList', params: { dom: domain } }">社員</router-link></li>
      <li><router-link :to="{ name: 'EvalGroup', params: { dom: domain } }">評価グループ</router-link></li>
      <li><router-link :to="{ name: 'EmployeeMaster', params: { dom: domain } }">社員マスター</router-link></li>
    </ul>
  </div>
</template>

<script>
export default {
  data () {
    return {
      domain: localStorage.getItem('domain')
    }
  },
  mounted () {
    this.$store.watch(
      (state, getters) => getters['Auth/domain'],
      (newValue) => {
        this.domain = newValue
      }
    )
  },
  methods: {
  },
  watch: {
  }
}
</script>
