export default {
  methods: {
    //
    // 文字列無害化
    //
    sanitizeChar (str) {
      return str.replace(/[\u0000-\u001F\u007F-\u009F]/g, '').replace(/ /g, '').replace(/\?/g, '').replace(/\+/g, '').replace(/\(/g, '').replace(/\)/g, '').replace(/\{/g, '').replace(/\}/g, '').replace(/\[/g, '').replace(/\]/g, '').replace(/</g, '').replace(/>/g, '')
    },
    //
    // Base64エンコード
    //
    encodeBase64 (str) {
      const utf8str = String.fromCharCode.apply(null, new TextEncoder().encode(str))
      return btoa(utf8str)
    },
    //
    // 全角数字を半角数字に変換
    full2Half (str) {
      return str.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function (s) {
        return String.fromCharCode(s.charCodeAt(0) - 0xFEE0)
      })
    },
    //
    // 生年月日（yy-mm-dd）から年齢を計算
    getAge (ymd) {
      if (ymd && ymd.match(/\d{4}-\d{2}-\d{2}/)) {
        const targetDay = new Date(ymd)
        const today = new Date()
        const diffms = today - targetDay
        const diffDays = parseInt(diffms / 1000 / 60 / 60 / 24)
        return targetDay ? Math.floor(diffDays / 365) : ''
      }
    },
    //
    // 得点％から評価を計算（定期評価）
    getGradeStr (percent) {
      let res = ''
      if (percent && percent !== undefined) {
        if (percent >= 86) {
          res = 'SS'
        } else if (percent >= 81) {
          res = 'S'
        } else if (percent >= 71) {
          res = 'A'
        } else if (percent >= 56) {
          res = 'B'
        } else if (percent >= 46) {
          res = 'B-'
        } else if (percent >= 40) {
          res = 'C'
        } else {
          res = 'D'
        }
      }
      return res
    },
    //
    // 得点％から評価を計算（賞与評価）
    getBonusGradeStr (percent) {
      let res = ''
      if (percent && percent !== undefined) {
        if (percent >= 71) {
          res = 'A'
        } else if (percent >= 51) {
          res = 'B'
        } else if (percent >= 40) {
          res = 'B-'
        } else {
          res = 'C'
        }
      }
      return res
    },
    //
    // 評価文字列から表示用ラベル取得
    getGradeRabel (str) {
      const clientData = this.$store.getters['Client/clientData']
      let found = ''
      if (clientData !== undefined && Object.keys(clientData).length > 0) {
        if ('ranks' in clientData.settings) {
          const ranks = clientData.settings.ranks
          if (ranks) {
            found = ranks.find(el => el.rank === str)
          }
        }
      }
      return found ? found.label : str
    },
    //
    // 等級数値から等級ラベルを取得
    getRankLabel (num) {
      const clientData = this.$store.getters['Client/clientData']
      let label = ''
      if (clientData !== undefined && Object.keys(clientData).length > 0) {
        if ('label' in clientData.settings.classdefs) {
          label = clientData.settings.classdefs.label[num - 1]
        }
      }
      return label
    },
    //
    // 得点％から評価を計算
    getGradeStrBonus (percent) {
      let res = ''
      if (percent && percent !== undefined) {
        if (percent >= 71) {
          res = 'A'
        } else if (percent >= 51) {
          res = 'B'
        } else if (percent >= 40) {
          res = 'B-'
        } else {
          res = 'C'
        }
      }
      return res
    },
    //
    // inputフォームでmax値で制限をかける
    restrictVal (e) {
      e.target.value = Math.min(e.target.max, e.target.value)
    },
    //
    // 数字でソート
    sortBy (key, e) {
      const siblings = e.target.parentElement.children
      Object.keys(siblings).forEach(el => {
        siblings[el].classList.remove('asc')
        siblings[el].classList.remove('desc')
      })
      const order = sessionStorage.getItem('order')
      switch (order) {
        case 'ASC':
          e.target.classList.add('asc')
          e.target.classList.remove('desc')
          sessionStorage.setItem('order', 'DESC')
          this.staffList.sort((a, b) => {
            return a[key] - b[key]
          })
          break
        case 'DESC':
          e.target.classList.add('desc')
          e.target.classList.remove('asc')
          sessionStorage.setItem('order', 'ASC')
          this.staffList.sort((a, b) => {
            return b[key] - a[key]
          })
          break
        default:
          e.target.classList.add('desc')
          e.target.classList.remove('asc')
          sessionStorage.setItem('order', 'ASC')
          this.staffList.sort((a, b) => {
            return a[key] - b[key]
          })
      }
    }
  },
  computed: {
    classLabel () {
      return (cls, specialist) => {
        let label = this.$store.getters['Client/clientData'].settings.classdefs.label[cls - 1]
        if (specialist === true || specialist === 'true') {
          switch (parseInt(cls)) {
            case 3:
              label = this.$store.getters['Client/clientData'].settings.classdefs.labelSP[0]
              break
            case 4:
              label = this.$store.getters['Client/clientData'].settings.classdefs.labelSP[1]
              break
          }
        }
        return label
      }
    },
    calcPoint () {
      return (arr) => {
        let total = 0
        let step = 1
        let gain = 0
        arr.forEach(el => {
          switch (el.max) {
            case 20:
              step = 4
              break
            case 15:
              step = 3
              break
            case 10:
              step = 2
              break
            default:
              step = 1
          }
          switch (el.point) {
            case 'SS':
              gain = 5
              break
            case 'A':
              gain = 4
              break
            case 'B':
              gain = 3
              break
            case 'C':
              gain = 2
              break
            case 'D':
              gain = 1
              break
          }
          total += gain + step
        })
        console.log(total)
        return total
      }
    },
    showAsDateTime () {
      return (dateStr) => {
        const date = new Date(dateStr)
        // const dow = date.getUTCDay()
        const dow = date.getDay()
        const arr = ['日', '月', '火', '水', '木', '金', '土']
        return date.getFullYear() + '/' + (date.getMonth() + 1) + '/' + date.getDate() + `(${arr[dow]})`
      }
    },
    //
    // Date型からyy-mm-dd
    dateToDatestr () {
      return (date) => {
        return date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2)
      }
    },
    //
    // yy-mm-ddから年月日表示
    dateToStr () {
      return (ymd) => {
        const date = new Date(ymd)
        return date.getFullYear() + '年' + (date.getMonth() + 1) + '月' + date.getDate() + '日'
      }
    },
    //
    // yy-mm-dd（入社年月日）から勤続年数
    calcBelongYears () {
      return (ymd) => {
        const joined = new Date(ymd)
        const today = new Date()
        const diffms = today - joined
        return Math.floor(((diffms / 1000) / (60 * 60 * 24)) / 365)
      }
    },
    //
    // 数値を金額表記に変換
    showAsMoneyStr () {
      return (num) => {
        return num ? '￥' + parseInt(num).toLocaleString() : ''
      }
    },
    //
    // Score, Pointから表示用のシンボルを表示
    scoreToIcon () {
      return (point, score) => {
        const percent = score / point * 100
        let res = ''
        if (percent && percent !== undefined) {
          if (percent >= 71) {
            res = '☆'
          } else if (percent >= 56) {
            res = '○'
          } else {
            res = '△'
          }
        }
        return res
      }
    },
    //
    // 評価シンボルからスローガン取得
    getGladeSlogan () {
      return (grade) => {
        const rankDef = this.$store.getters['Initial/rankDefs']
        const found = Object.keys(rankDef).find(el => rankDef[el].rank === grade)
        return found ? rankDef[found].slogan : ''
      }
    },
    //
    // スタッフ数取得
    staffCount () {
      const clientData = this.$store.getters['Client/clientData']
      return 'users' in clientData ? clientData.users.avail_roles.author : '--'
    }
  }
}
