import axios from 'axios'

const state = {
  clientList: {},
  slug: true,
  tel: true,
  email: true
}

const getters = {
  clientList: (state) => state.clientList,
  slug: (state) => state.slug,
  tel: (state) => state.tel,
  email: (state) => state.email
}

const mutations = {
  clientList (state, clientList) {
    state.clientList = clientList
  },
  slug (state, slug) {
    state.slug = slug
  },
  tel (state, tel) {
    state.tel = tel
  },
  email (state, email) {
    state.email = email
  }
}

const actions = {
  //
  // クライアントリスト読み込み
  async loadClientList ({ getters, commit }) {
    commit('processing', true, { root: true })
    return new Promise((resolve) => {
      axios
        .get('/wp-json/jjs/v2/client/list')
        .then((response) => {
          console.log(response.data)
          commit('clientList', response.data)
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  },
  //
  // ドメイン有効性確認
  chkExistSlug ({ commit }, str) {
    axios
      .post('/wp-json/jjs/v2/client/chkexist/slug', str)
      .then((response) => {
        commit('slug', true)
      })
      .catch((error) => {
        if (error.response.status === 403) {
          commit('slug', false)
        }
      })
  },
  //
  // 電話番号有効性確認
  async chkExistTel ({ commit }, data) {
    return new Promise((resolve) => {
      axios
        .post('/wp-json/jjs/v2/client/chkexist/tel', data)
        .then((response) => {
          commit('tel', true)
        })
        .catch((error) => {
          if (error.response.status === 403) {
            commit('tel', false)
          }
        })
    })
  },
  //
  // メールアドレス有効性確認
  async chkExistEmail ({ commit }, data) {
    console.log('check exists the email')
    return new Promise((resolve) => {
      axios
        .post('/wp-json/jjs/v2/client/chkexist/email', {
          old: data.old,
          new: data.new
        })
        .then((response) => {
          // console.log(response.data)
          commit('email', true)
        })
        .catch((error) => {
          if (error.response.status === 403) {
            commit('email', false)
          }
        })
    })
  }
}

const Admin = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

export default Admin
