import axios from 'axios'
import router from '@/router'
axios.defaults.baseURL = 'https://xs041845.xsrv.jp/jjstats'
axios.defaults.headers.common.Authorization = ''

const state = {
  authData: JSON.parse(localStorage.getItem('authData')),
  restorable: false,
  domain: localStorage.getItem('domain'),
  currentCompany: localStorage.getItem('currentCompany'),
  masterToken: localStorage.getItem('masterToken'),
  token: localStorage.getItem('token'),
  capability: localStorage.getItem('capability'),
  userid: localStorage.getItem('userid'),
  askeval: sessionStorage.getItem('askeval')
}

const getters = {
  authData: (state) => state.authData,
  restorable: (state) => state.restorable,
  domain: (state) => state.domain,
  currentCompany: (state) => state.currentCompany,
  masterToken: (state) => state.masterToken,
  token: (state) => state.token,
  capability: (state) => state.capability,
  userid: (state) => state.userid,
  askeval: (state) => state.askeval
}

const mutations = {
  authData (state, authData) {
    state.authData = authData
    localStorage.setItem('authData', JSON.stringify(authData))
  },
  restorable (state, restorable) {
    state.restorable = restorable
  },
  domain (state, domain) {
    state.domain = domain
    if (domain) {
      axios.defaults.baseURL = `https://xs041845.xsrv.jp/jjstats/${domain}`
      localStorage.setItem('domain', domain)
    } else {
      axios.defaults.baseURL = 'https://xs041845.xsrv.jp/jjstats'
      localStorage.removeItem('domain')
    }
  },
  currentCompany (state, currentCompany) {
    state.currentCompany = currentCompany
    localStorage.setItem('currentCompany', currentCompany)
  },
  masterToken (state, masterToken) {
    state.masterToken = masterToken
    if (masterToken) {
      axios.defaults.headers.common.Authorization = `Bearer ${masterToken}`
      localStorage.setItem('masterToken', masterToken)
    } else {
      localStorage.removeItem('masterToken')
    }
  },
  token (state, token) {
    state.token = token
    if (token) {
      axios.defaults.headers.common.Authorization = `Bearer ${token}`
      localStorage.setItem('token', token)
    } else {
      localStorage.removeItem('token')
    }
  },
  capability (state, capability) {
    state.capability = capability
    localStorage.setItem('capability', capability)
  },
  userid (state, userid) {
    state.userid = userid
    localStorage.setItem('userid', userid)
  },
  askeval (state, askeval) {
    state.askeval = askeval
    sessionStorage.setItem('askeval', askeval)
  }
}

const actions = {
  //
  // マスターログイン
  async login ({ getters, commit, dispatch }) {
    axios.defaults.baseURL = 'https://xs041845.xsrv.jp/jjstats'
    axios.defaults.headers.common.Authorization = ''
    // console.log('try master login')
    return new Promise((resolve) => {
      axios
        .post('/wp-json/jwt-auth/v1/token/', getters.authData)
        .then((response) => {
          if (response.data.capability === 'superadmin') {
            commit('masterToken', response.data.token)
            commit('capability', 'superadmin')
            commit('domain', false)
            commit('currentCompany', '')
            commit('company', response.data.user_display_name, { root: true })
            commit('name', '', { root: true })
            router.push('/admin/dashboard')
          } else {
            console.log('master login done')
            commit('domain', response.data.slug)
            commit('askeval', response.data.askeval)
            dispatch('branchLogin', response.data.slug)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    })
  },
  //
  // ブランチログイン
  async branchLogin ({ rootGetters, getters, commit, dispatch }, slug) {
    axios.defaults.headers.common.Authorization = ''
    axios.defaults.baseURL = `https://xs041845.xsrv.jp/jjstats/${slug}`
    const templatename = rootGetters.currentTemplateName
    // console.log('branch login!!')
    if (templatename !== 'login' && templatename !== 'ClientLogin' && templatename !== 'StaffLogin' && getters.domain === slug) {
      commit('token', localStorage.getItem('token'))
      dispatch('preparePartialData', getters.userid)
      return
    }
    return new Promise((resolve) => {
      axios
        .post('/wp-json/jwt-auth/v1/token/', getters.authData)
        .then((response) => {
          // console.log(`▶︎▶︎CURRENTLY LOGIN [${response.data.title}]`)
          commit('token', response.data.token)
          commit('domain', slug)
          commit('currentCompany', response.data.title)
          commit('userid', response.data.user_id)
          commit('askeval', response.data.askeval)

          if (response.data.capability !== 'superadmin') {
            commit('capability', response.data.capability)
            commit('masterToken', false)
            commit('company', response.data.title, { root: true })
            commit('name', response.data.user_display_name, { root: true })
            // ログイン後の画面遷移先
            if (templatename === 'login') {
              if (response.data.capability === 'owner') {
                router.push({ name: 'Client', params: { dom: slug } })
              } else {
                router.push({ name: 'StaffDashboard' })
              }
            } else if (templatename === 'StaffLogin') {
              router.push({ name: 'StaffDashboard' })
            }
          }
          dispatch('prepareEntireData', response.data.user_id)
        })
        .catch((error) => {
          console.log(error)
          router.push({ name: 'login' })
        })
    })
  },
  //
  // クライアントログイン
  async clientLogin ({ rootGetters, getters, commit, dispatch }, slug) {
    axios.defaults.headers.common.Authorization = ''
    axios.defaults.baseURL = `https://xs041845.xsrv.jp/jjstats/${slug}`
    const templatename = rootGetters.currentTemplateName
    if (templatename !== 'ClientLogin' && getters.domain === slug) {
      commit('token', localStorage.getItem('token'))
      dispatch('preparePartialData', getters.userid)
      return
    }
    return new Promise((resolve) => {
      axios
        .post('/wp-json/jwt-auth/v1/token/', getters.authData)
        .then((response) => {
          // console.log(`▶︎▶︎CURRENTLY LOGIN [${response.data.title}]`)
          commit('token', response.data.token)
          commit('domain', slug)
          commit('currentCompany', response.data.title)
          commit('userid', response.data.user_id)

          if (response.data.capability !== 'superadmin') {
            commit('capability', response.data.capability)
            commit('masterToken', false)
            commit('company', response.data.title, { root: true })
            commit('name', response.data.user_display_name, { root: true })
            // ログイン後の画面遷移先
            if (templatename === 'ClientLogin') {
              if (response.data.capability === 'owner') {
                router.push({ name: 'Client', params: { dom: slug } })
              } else {
                router.push({ name: 'StaffDashboard' })
              }
            } else if (templatename === 'StaffLogin') {
              router.push({ name: 'StaffDashboard' })
            }
          }
          dispatch('prepareEntireData', response.data.user_id)
        })
        .catch((error) => {
          console.log(error)
          router.push({ name: 'login' })
        })
    })
  },
  //
  // 特定社員としてログイン
  loginAs ({ rootGetters, getters, commit }) {
    const staffData = rootGetters['Staff/staffData']
    axios.defaults.headers.common.Authorization = ''
    axios
      .post('/wp-json/jwt-auth/v1/token/', {
        username: staffData.email,
        password: staffData.password
      })
      .then((response) => {
        commit('capability', response.data.capability)
        commit('token', response.data.token)
        commit('userid', response.data.user_id)
        commit('restorable', true)
        router.push({ name: 'StaffDashboard' })
      })
      .catch((error) => {
        console.log(error)
      })
  },
  //
  // 元のログイン状態を復元
  restoreLogin ({ getters, commit }) {
    const userid = getters.userid
    axios.defaults.headers.common.Authorization = ''
    axios
      .post('/wp-json/jwt-auth/v1/token/', getters.authData)
      .then((response) => {
        commit('capability', response.data.capability)
        commit('token', response.data.token)
        commit('userid', response.data.user_id)
        commit('restorable', false)
        router.push({ name: 'StaffDetail', params: { dom: getters.domain, id: userid } })
      })
      .catch((error) => {
        console.log(error)
      })
  },
  //
  // 全データ読み込み
  prepareEntireData ({ getters, commit, dispatch }, userid) {
    // All
    dispatch('Defs/resetAllDefs', null, { root: true })
    dispatch('Client/loadClientData', null, { root: true })
    dispatch('Defs/loadEvalDefs', null, { root: true })

    // client

    // client staff
    if (getters.capability === 'manager' || getters.capability === 'staff') {
      dispatch('Defs/loadSalaryDefs', null, { root: true })
      dispatch('Eval/getList', userid, { root: true })
      dispatch('SelfExam/loadList', null, { root: true })
    }
    // superadmin or owner
    if (getters.capability === 'superadmin' || getters.capability === 'owner') {
      dispatch('Defs/loadClassDefs', null, { root: true })
      dispatch('Owner/loadSummary', null, { root: true })
    }
    // manager
    if (getters.capability === 'manager') {
      dispatch('Manager/loadMembers', null, { root: true })
      // dispatch('Eval/getEvalPendingList', null, { root: true })
      dispatch('Manager/loadNeedEvalList', null, { root: true })
    }
    // staff
    if (getters.capability === 'staff') {
      dispatch('Staff/loadDetail', userid, { root: true })
    }
  },
  //
  // 部分的データロード
  preparePartialData ({ getters, commit, dispatch }, userid) {
    // console.log('PREPARE PARTIAL DATA')
    // ALL
    dispatch('Client/loadClientData', null, { root: true })
    dispatch('Defs/loadEvalDefs', null, { root: true })

    // superadmin or owner
    if (getters.capability === 'superadmin' || getters.capability === 'owner') {
      dispatch('Defs/loadClassDefs', null, { root: true })
      dispatch('Owner/loadSummary', null, { root: true })
    }

    // client staff
    if (getters.capability === 'manager' || getters.capability === 'staff') {
      dispatch('Defs/loadSalaryDefs', null, { root: true })
      dispatch('SelfExam/chkIfDone', userid, { root: true })
      dispatch('SelfExam/loadList', null, { root: true })
      dispatch('Eval/chkAskevalStatus', null, { root: true })
      dispatch('Jirei/chkExistJirei', null, { root: true })
    }
    // manager
    if (getters.capability === 'manager') {
      // console.log('IM MANAGER')
      dispatch('Manager/loadMembers', null, { root: true })
      // dispatch('Eval/getEvalPendingList', null, { root: true })
      dispatch('Manager/loadNeedEvalList', null, { root: true })
    }
  },
  //
  // Keep Alive
  keepAlive ({ commit, dispatch }) {},
  //
  // マスターログイン状態復元
  restoreMasterLogin ({ commit }) {
    // console.log('▶︎▶︎RESTORED MASTER LOGIN')
    commit('domain', '')
    commit('token', '')
    commit('currentCompany', '')
    commit('masterToken', localStorage.getItem('masterToken'))
  },
  //
  // ログアウト
  logout ({ getters, commit }) {
    if (getters.capability === 'superadmin') {
      Object.keys(getters).forEach(el => {
        if (el !== 'authData') {
          commit(el, '')
        }
      })
      router.push('/login')
    } else {
      const domain = getters.domain
      Object.keys(getters).forEach(el => {
        if (el !== 'authData') {
          commit(el, '')
        }
      })
      router.push('/' + domain + '/login')
    }
  }
}

const Auth = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

export default Auth
