import axios from 'axios'

const state = {
  list: [],
  jireiData: {}
}

const getters = {
  list: (state) => state.list,
  jireiData: (state) => state.jireiData
}

const mutations = {
  list (state, list) {
    state.list = list
  },
  jireiData (state, jireiData) {
    state.jireiData = jireiData
  }
}

const actions = {
  //
  // 辞令有無確認
  chkExistJirei ({ commit }) {
    axios
      .get('/wp-json/jjs/v2/jirei/chkstatus')
      .then((response) => {
        console.log(response.data)
        commit('list', response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  //
  // 辞令取得
  getJirei ({ commit }, id) {
    axios
      .get('/wp-json/jjs/v2/jirei/' + id)
      .then((response) => {
        console.log(response.data)
        commit('jireiData', response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  //
  // 辞令確認ずみにマーク
  dismiss ({ context }, id) {
    axios
      .get('/wp-json/jjs/v2/jirei/dismiss/' + id)
      .then((response) => {
        console.log(response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

const Jirei = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

export default Jirei
