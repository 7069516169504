<template>
  <div>
    <ul class="control">
      <li
        :class="{ current: mode === '在籍' }"
      ><span @click="setMode('在籍')">在籍中</span></li>
      <li
        :class="{ current: mode === '休職中' }"
      ><span @click="setMode('休職中')">休職中</span></li>
      <li
        :class="{ current: mode === '退職' }"
      ><span @click="setMode('退職')">退職者</span></li>
      <li
        :class="{ current: mode === 'ALL' }"
      ><span @click="setMode('ALL')">ALL</span></li>
      <li
        v-if="cap === 'superadmin'"
        :class="{ current: mode === 'Deleted' }"
      ><span @click="setMode('Deleted')">削除済みユーザー</span></li>
    </ul>
    <ul class="control adminaction">
      <li
        :class="{ active: adminMode }"
      >
        <span @click="adminAction">管理者操作</span>
      </li>
      <li v-if="adminMode">
        <router-link :to="{ name: 'BulkRegistration' }">社員一括登録</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data () {
    return {
      mode: '在籍',
      adminMode: false,
      cap: this.$store.getters['Auth/capability']
    }
  },
  mounted () {
    this.$store.watch(
      (state, getters) => getters.mode,
      (newValue) => {
        this.mode = newValue
      }
    )
    this.$store.watch(
      (state, getters) => getters.adminMode,
      (newValue) => {
        this.adminMode = newValue
      }
    )
    this.setMode('在籍')
  },
  methods: {
    setMode (mode) {
      this.$store.dispatch('Staff/loadStaffMaster', mode)
      this.$router.push({ name: 'EmployeeMaster' })
      this.$store.commit('mode', mode)
    },
    adminAction () {
      if (this.$store.getters.adminMode) {
        this.$store.commit('adminMode', false)
      } else {
        this.$store.commit('adminMode', true)
      }
    },
    bulkRegistration () {
      this.$router.push({ name: 'BulkRegistration' })
      this.$store.commit('mode', '在籍')
    }
  }
}
</script>
