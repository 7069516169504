import axios from 'axios'

const state = {
  mode: '',
  show: false,
  content: false,
  payload: {},
  error: false,
  duplicateEmail: false
}

const getters = {
  mode: (state) => state.mode,
  show: (state) => state.show,
  content: (state) => state.content,
  payload: (state) => state.payload,
  error: (state) => state.error,
  duplicateEmail: (state) => state.duplicateEmail
}

const mutations = {
  mode (state, mode) {
    state.mode = mode
  },
  show (state, show) {
    state.show = show
  },
  content (state, content) {
    state.content = content
  },
  payload (state, payload) {
    state.payload = payload
  },
  error (state, error) {
    state.error = error
  },
  duplicateEmail (state, duplicateEmail) {
    state.duplicateEmail = duplicateEmail
  }
}

const actions = {
  //
  // 携帯電話番号の重複を確認
  chkDuplicate ({ commit }, telno) {
    axios
      .post('/wp-json/jjs/v2/staff/chk-duplicate', telno)
      .then((response) => {
        console.log('available telno')
        commit('error', false)
      })
      .catch((error) => {
        console.log(error)
        if (error.response.status === 403) {
          console.log('unavailable telno')
          commit('error', true)
        }
      })
  },
  //
  // メールアドレスの重複を確認
  chkDuplicateEmail ({ commit }, email) {
    axios
      .post('/wp-json/jjs/v2/staff/chkemail', email)
      .then((response) => {
        console.log('available email adress')
        commit('duplicateEmail', false)
      })
      .catch((error) => {
        console.log(error)
        if (error.response.status === 403) {
          console.log('unavailable email adress')
          commit('duplicateEmail', true)
        }
      })
  }
}

const Modal = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

export default Modal
