<template>
  <div id="pagetitle">
    <h1 v-if="companyname">
      <div class="companyname">{{ companyname }}</div>
      <div
        v-if="$route.name === 'StaffDetail' || $route.name === 'StaffHistory' || $route.name === 'StaffHistoryEval' || $route.name === 'StaffHistoryBonus' || $route.name === 'EvalTeiki' || $route.name === 'EvalBonus'"
      >{{ staffName }}</div>
      <div>
        {{ title }}
        <span
          v-if="$route.name === 'EvalTeiki' || $route.name === 'EvalBonus'"
          class="status"
        >{{ status }}</span>
        <span
          v-if="$route.name === 'SelfEvaluation' || $route.name === 'SelfEvalBonus' || $route.name === 'SelfEvalDetail' || $route.name === 'SelfEvalBonusDetail'"
          class="status"
        >{{ status }}</span>
      </div>
    </h1>
    <h1 v-else>
      <div>{{ title }}</div>
    </h1>
  </div>
</template>

<script>
export default {
  data () {
    return {
      companyname: this.$store.getters['Auth/currentCompany'],
      title: this.$store.getters.title,
      status: this.$store.getters.titleAdd,
      staffName: this.$store.getters['Staff/staffData'].nickname
    }
  },
  mounted () {
    this.$store.watch(
      (state, getters) => getters['Auth/currentCompany'],
      (newValue) => {
        this.companyname = newValue
      }
    )
    this.$store.watch(
      (state, getters) => getters.title,
      (newValue) => {
        this.title = newValue
      }
    )
    this.$store.watch(
      (state, getters) => getters.titleAdd,
      (newValue) => {
        this.status = newValue
      }
    )
    this.$store.watch(
      (state, getters) => getters['SelfExam/status'],
      (newValue) => {
        switch (newValue) {
          case 'draft':
            this.status = '未提出'
            break
          case 'publish':
            this.status = '提出済み'
            break
          default:
            this.status = '新規作成'
        }
      }
    )
    this.$store.watch(
      (state, getters) => getters['Staff/staffData'],
      (newValue) => {
        this.staffName = newValue.nickname
      }
    )
  }
}
</script>
