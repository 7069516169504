<template>
  <div>
    <div class="qrcode">
      <h5>スマートフォンアクセス用QRコード</h5>
      <vue-qr
        v-if="qrUrl"
        :text="qrUrl"
      ></vue-qr>
    </div>
  </div>
</template>

<script>
import vueQr from 'vue-qr/src/packages/vue-qr.vue'
export default {
  components: {
    vueQr
  },
  data () {
    return {
      qrUrl: this.$store.getters['Staff/staffData'].url
    }
  },
  mounted () {
    this.$store.watch(
      (state, getters) => getters['Staff/staffData'],
      (newValue) => {
        this.qrUrl = newValue.url
      }
    )
  },
  methods: {
  },
  computed: {
  },
  watch: {
  }
}
</script>
