<template>
  <div>
    <div v-if="cap === 'owner' || cap === 'superadmin'" class="askeval">
      <h3>評価リクエスト</h3>
      <ul>
        <li>
          <button
            @click="toggleAskEval('periodical')"
            :class="{ active: parseInt(clientData.options.askeval.periodical) > 0 }"
          >定期</button></li>
        <li>
          <button
            @click="toggleAskEval('bonus')"
            :class="{ active: parseInt(clientData.options.askeval.bonus) > 0 }"
          >賞与</button></li>
      </ul>
      <div class="status">
        <span v-if="parseInt(clientData.options.askeval.periodical) > 0">定期評価リクエスト中</span>
        <span v-if="parseInt(clientData.options.askeval.bonus) > 0">賞与評価リクエスト中</span>
      </div>
    </div>

    <div v-if="cap === 'owner' || cap === 'superadmin'" class="owneraction promotestaff" @click="approvePromote">
      <span><i class="fa-solid fa-person-arrow-up-from-line"></i>昇格昇給</span>
    </div>

    <div v-if="cap === 'owner' || cap === 'superadmin'" class="owneraction paybonus" @click="payBonus">
      <span><i class="fa-solid fa-sack-dollar"></i>賞与支給</span>
    </div>

  </div>
</template>

<script>
export default {
  data () {
    return {
      cap: localStorage.getItem('capability'),
      clientData: this.$store.getters['Client/clientData']
    }
  },
  mounted () {
    console.log(this.$route.name)
    this.$store.watch(
      (state, getters) => getters['Auth/capability'],
      (newValue) => {
        this.cap = newValue
      }
    )
    this.$store.watch(
      (state, getters) => getters['Client/clientData'],
      (newValue) => {
        this.clientData = newValue
      }
    )
  },
  methods: {
    toggleAskEval (cat) {
      this.$store.commit('Modal/mode', cat)
      switch (cat) {
        case 'periodical':
          if (parseInt(this.clientData.options.askeval.periodical) > 0) {
            // inactivate periodical eval
            this.canselEval('定期評価')
          } else {
            // activate periodical eval
            this.requestEval('定期評価')
          }
          break
        case 'bonus':
          if (parseInt(this.clientData.options.askeval.bonus) > 0) {
            // inactivate bonus
            this.canselEval('賞与評価')
          } else {
            // activate bonus
            this.requestEval('賞与評価')
          }
          break
      }
    },
    //
    // 評価リクエスト
    requestEval (str) {
      if (confirm(`${str}を開始します。\n実行しますか？`)) {
        this.$store.commit('Modal/show', true)
        this.$store.commit('Modal/content', 'set evaluation range')
      }
    },
    //
    // 評価リクエストキャンセル
    canselEval (str) {
      if (confirm(`${str}を終了します。`)) {
        let cat = ''
        switch (str) {
          case '定期評価':
            cat = 'periodical'
            break
          case '賞与評価':
            cat = 'bonus'
            break
        }
        this.$store.dispatch('Eval/evalDeactivate', cat)
        this.clientData.options.askeval.periodical = '0'
      }
    },
    //
    // 定期評価リクエスト
    requestSelfCheck () {
      if (confirm('評価提出を開始します。\n実行しますか？')) {
        this.$store.commit('Modal/show', true)
        this.$store.commit('Modal/content', 'set evaluation range')
      }
    },
    //
    // 定期評価リクエストキャンセル
    canselSelfCheck () {
      if (confirm('定期評価を終了します。')) {
        this.$store.dispatch('Eval/evalDeactivate', 'periodical')
        this.clientData.options.askeval.periodical = '0'
      }
    },
    //
    // 賞与評価リクエスト
    requestEvalBonus () {
      if (confirm('賞与評価を開始します。\n実行しますか？')) {
        this.$store.dispatch('Eval/evalRequest', 'bonus')
      }
    },
    //
    // 賞与評価リクエストキャンセル
    canselEvalBonus () {
      if (confirm('賞与評価を終了します。')) {
        this.$store.dispatch('Eval/evalDeactivate', 'bonus')
        this.clientData.options.askeval.bonus = '0'
      }
    },
    //
    // 賞与支払い画面へ遷移
    payBonus () {
      this.$router.push({ name: 'PayBonus' })
    },
    //
    // 社員昇格画面へ遷移
    approvePromote () {
      this.$router.push({ name: 'ApprovePromote' })
    }
  }
}
</script>

<style scoped>
@media (min-width: 1700px) {
  .selfchk .status {
    padding: 30px 20px;
  }
  .selfchk .action span {
    padding: 10px;
  }
  .owneraction {
    padding: 30px 10px;
  }
}
@media (max-width: 1699px) {
  .selfchk .status {
    font-size: 12px;
    padding: 20px 5px;
  }
  .selfchk .action span {
    font-size: 12px;
    padding: 5px;
  }
  .owneraction {
    font-size: 14px;
    padding: 20px 5px;
  }
}
.selfchk {
  padding: 10px;
  background-color: #fff;
  border: 1px solid var(--border-color);
  margin-bottom: 20px;
}
.selfchk h3 {
  font-weight: bold;
  color: var(--theme-color);
  margin-bottom: 10px;
}
.selfchk .status {
  text-align: center;
  background-color: var(--weak-color);
  margin-bottom: 10px;
  font-weight: bold;
  line-height: 1.1;
}
.selfchk .action span {
  display: block;
  text-align: center;
  background-color: var(--thead-color);
  color: #fff;
  font-weight: bold;
}
.selfchk .action span:hover {
  cursor: pointer;
}
.owneraction {
  background-color: #fff;
  background-color: var(--weak-color);
  margin-bottom: 10px;
  font-weight: bold;
  color: #fff;
  text-align: center;
  box-shadow: 3px 3px 0 rgba(0,0,0,0.5);
}
.owneraction:hover {
  cursor: pointer;
}
</style>
